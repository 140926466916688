.background-wrapper {
  width: 100vw;
  height: 100vh;
  background: url("https://decentro-public.s3.ap-south-1.amazonaws.com/invoice/img/background.png")
    no-repeat center center fixed;
  background-size: cover;
  position: relative;
}

/* Toast UI component */
.error-response-block {
  width: 86%;
  background: #fdf3f3;
  color: #e06060;
  text-align: center;
  border-radius: 0.4rem;
  margin-top: 1rem;
  padding: 1rem;
  font-size: 0.8rem;
  font-weight: 600;
}
