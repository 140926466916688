/* **************** CARD - WRAPPER **************** */
:root {
  --input-font-size: 0.825rem;
  --btn-font-size: 0.8rem;
  --bottom-spacing: 0.8rem;
  --border-radius: 0.5rem;
}

.card-wrapper {
  /* width: 400px; */
  width: max-content;
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 1.25rem;
  background: #ffffff;
  box-shadow: 0px 3px 40px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  padding: 2.5rem 3.5rem;
}

.loading-heading {
  font-size: 1.2rem;
  font-weight: 600;
  color: #43425d;
  text-align: center;
  padding: 8rem;
}

/* loader styles */
.loader-balls {
  width: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.4rem;
}
.loader-balls__item {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #0092ff;
}
.loader-balls__item:nth-child(1) {
  animation: bouncing 0.4s alternate infinite
    cubic-bezier(0.6, 0.05, 0.15, 0.95);
}
.loader-balls__item:nth-child(2) {
  animation: bouncing 0.4s 0.1s alternate infinite
    cubic-bezier(0.6, 0.05, 0.15, 0.95) backwards;
}
.loader-balls__item:nth-child(3) {
  animation: bouncing 0.4s 0.2s alternate infinite
    cubic-bezier(0.6, 0.05, 0.15, 0.95) backwards;
}
@keyframes bouncing {
  0% {
    transform: translate3d(0, 10px, 0) scale(1.2, 0.85);
  }
  100% {
    transform: translate3d(0, -20px, 0) scale(0.9, 1.1);
  }
}
