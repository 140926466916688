/* **************** CARD - WRAPPER **************** */
:root {
  --input-font-size: 0.825rem;
  --btn-font-size: 0.8rem;
  --bottom-spacing: 0.8rem;
  --border-radius: 0.5rem;
}

.card-wrapper {
  /* width: 400px; */
  width: max-content;
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 1.25rem;
  background: #ffffff;
  box-shadow: 0px 3px 40px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  padding: 2.5rem 3.5rem;
}

.sign-in-heading {
  font-size: 1.2rem;
  font-weight: 600;
  color: #43425d;
  text-align: center;
  padding-bottom: 2rem;
}

.form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20rem;
}

.field-wrapper {
  margin-bottom: 0.8rem;
  width: 18rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.field-wrapper label {
  display: block;
  font-size: var(--input-font-size);
  font-weight: 500;
  color: #43425d;
  margin-bottom: 0.4rem;
  align-self: flex-start;
  margin-left: -8px;
}

.card-wrapper input {
  display: block;
  width: 100%;
  font-size: 14px;
  color: #43425d;
  font-weight: 300;
  border: 1px solid #43425d4d;
  padding: 0.8rem 0.6rem;
  border-radius: 0.5rem;
  margin-bottom: 0.2rem;
}

.error-message {
  font-size: 0.8rem;
  font-weight: 400;
  align-self: flex-start;
  margin-bottom: 0.8rem;
  margin-left: -8px;
  color: red;
}

.theme-btn {
  font-size: var(--btn-font-size);
  width: 86%;
  border-radius: 10px;
  background-color: #0092ff;
  padding: 0.875rem 1.2rem;
  margin-top: 1rem;
  color: #fff;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.theme-btn__disabled {
  font-size: var(--btn-font-size);
  width: 86%;
  border-radius: 10px;
  background-color: #0092ff;
  padding: 0.875rem 1.2rem;
  margin-top: 1rem;
  color: #fff;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
  cursor: not-allowed;
}

.custom-loader {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: radial-gradient(farthest-side, #fff 94%, #0000) top/3px 3px
      no-repeat,
    conic-gradient(#0000 30%, #fff);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 3px), #000 0);
  animation: s3 1s infinite linear;
}

@keyframes s3 {
  100% {
    transform: rotate(1turn);
  }
}
