.success-card-wrapper {
  /* width: 400px; */
  width: max-content;
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 1.25rem;
  background: #ffffff;
  box-shadow: 0px 3px 40px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  padding: 1.5rem 1.5rem;
}

.success-content-container {
  width: 100%;
  background-color: #daf7e8;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  border-radius: 1rem;
}

.success-content-container img {
    padding: 1rem;
}


.message-heading {
  font-size: 1.25rem;
  font-weight: 500;
}

.message-heading-subtitle {
  font-size: 1rem;
  font-weight: 500;
}

.footer-text {
  font-size: 0.725rem;
  font-weight: 500;
  text-align: center;
  margin-top: 2rem;
  padding: 1rem;
}
